














































@import "../../../style/variables";

#toolbar {
    height: var(--toolbar-height);
    display: inline-flex;
    flex-wrap: nowrap;
    border-bottom: solid 1px #ccc;
}
.current {
    line-height: var(--toolbar-height);
}
.avatar-container {
    height: 50px;
    margin-right: 30px;
    .avatar-wrapper {
        cursor: pointer;
        margin-top: 5px;
        position: relative;
        .user-avatar {
            width: 40px;
            height: 40px;
            border-radius: 10px;
        }
        .el-icon-caret-bottom {
            position: absolute;
            right: -20px;
            top: 25px;
            font-size: 12px;
        }
    }
}
