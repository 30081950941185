




























@font-face {
	font-family: frogFont;
	src: url("../../../assets/font/Sketchalot.ttf");
}

#sidebar-logo {
	min-height: var(--toolbar-height);
	display: flex;
	align-items: center;
	justify-content: center;
	overflow: hidden;

	img {
		width: 32px;
		height: 32px;
	}

	#sidebar-logo-text {
		display: inline;
		color: #fff;
		white-space: nowrap;
		line-height: var(--toolbar-height);
		font-size: 20px; 
		font-family: Arial, Helvetica, sans-serif;
	}
}
