























#layout {
	display: flex;
	height: 100vh;
	width: 100vw;
	font-family: 'Avenir', Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-webkit-transform: translateZ(0);
	color: #2c3e50;
	overflow: hidden;
}

