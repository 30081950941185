
























#sidebar-activator {
	width: var(--toolbar-height);
	text-align: center;
	line-height: var(--toolbar-height);
	padding-left: calc(var(--sidebar-width) - 65px);
	margin-right: 30px;
	transition: padding-left 0.3s;

	#activator-icon {
		transition: transform 0.3s;

		&.activator-turn {
			transform: rotate(-180deg);
		}
	}
}
