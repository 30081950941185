



















.outside {
	width: calc(100vw);
	display: flex;
	flex-direction: column;
	background-color: #f0f0f1;
	padding-left: 65px;
}
